<template>
  <div class="">
    <Navbar :merchantName="this.merchantName" />
    <merchant-nav-bar
      :locations="this.pickupLocations"
      :merchantName="this.merchantName"
      :selectedLocation="this.pickupLocationCode"
    />
    <merchant-main-body :bulkOrders="this.bulkPickups" />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import axios from "axios";

import Navbar from "../pages-sections/Navbar.vue";
import MerchantNavBar from "../pages-sections/MerchantNavBar.vue";
import MerchantMainBody from "../pages-sections/MerchantMainBody.vue";

export default {
  name: "MerchantPage",
  components: {
    Navbar,
    MerchantNavBar,
    MerchantMainBody,
  },
  data() {
    return {
      pickupLocationCode: "",
      searchpickupLocationCode: "",
      employeeAvatarIsValid: true,
      notifyLoader: false,
      isRequestDone: false,
      isBarcodeCopied: false,
      bulkPickups: [],
      pickupLocations: [],
      merchantName: "",
      isLoading: true,
    };
  },
  mounted() {
    this.pickupLocationCode = this.$route.params?.pickupLocationCode;
    if (this.pickupLocationCode) {
      this.getMerchantBulkPickups();
    }
  },
  methods: {
    async getMerchantBulkPickups() {
      const request = await axios.get(
        `${process.env.VUE_APP_COURIER_API}/user/merchantEta/${this.pickupLocationCode}`
        // `http://localhost:8000/user/merchantEta/${this.pickupLocationCode}`
      );

      if (request.data) {
        this.merchantName = request.data.merchantName;
        this.pickupLocations = request.data.pickupLocations;
        this.bulkPickups = this.pickupLocations.find(
          (pl) => pl.locationCode === this.pickupLocationCode
        ).bulkPickups;
      }

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/search_bar.scss";

$white: #fff;
$orange: #f68b24;
$orange8: rgb(246 139 36 / 80%);
$black: #363636;
$warning: #f44336;

@mixin flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-driver {
  width: 100%;
  min-height: 100vh;
  background: $orange;
  background-image: url("../../assets/images/bottom-bg.svg"),
    url("../../assets/images/vespa-no-logo.svg"),
    linear-gradient(0deg, $white, $white),
    url("../../assets/images/dash-vertical.svg");
  background-position: bottom left, bottom right 10%, top right,
    top 65% right 4px;
  background-repeat: no-repeat;
  background-size: 50%, 18%, 50% 50%, 10px;

  display: flex;
  flex-direction: column;
}

.card-container {
  @include flex-container;
  flex: 1;
  flex-wrap: wrap;
}

.flex-form {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 2rem 6rem;
  margin: 0 1rem;
  background: linear-gradient(45deg, rgb(145 108 108), $orange);
  border-radius: 12px;
  box-shadow: 2px 6px 20px 0px #4d3219;
  min-height: 300px;

  h3 {
    color: $white;

    .strong {
      font-size: 1.5em;
      font-weight: bold;
    }
    .op-5 {
      opacity: 0.75;
    }
  }
  form {
    width: 100%;
  }
}

.img {
  margin: 0.5rem 0 1rem 0;

  img {
    width: 35vw;
    height: 35vw;
    min-width: 140px;
    min-height: 140px;
    max-width: 300px;
    max-height: 300px;
    object-fit: fill;
    border-radius: 12px;
  }

  .avatar {
    object-fit: cover;
  }
}

.driver-name,
.personal-details {
  @include flex-container;
  flex-direction: column;

  .driver-name {
    margin-bottom: 0.5rem;

    h4 {
      color: $white;
      font-size: 2em;
      padding: 0;
    }
  }
  span {
    color: $black;
    margin-top: 0.5rem;
  }
}

.not-hired,
.warning-container {
  border-radius: 12px;
  overflow: hidden;
  max-width: 400px;
  width: 100%;
  margin: 0 1rem;
}

.warning-container {
  @include flex-container;
  flex-direction: column;
}
.w-message,
.notify-holder,
.request-msg {
  @include flex-container;
  flex-direction: column;
  width: 100%;
}

.w-message {
  background: $warning;
  padding: 2rem 3rem;
  color: $white;

  h2 {
    font-weight: bold;
    margin: 0;
    margin: 1rem 0 0.3rem 0;
  }
  span {
    text-align: center;
  }
}
.request-msg h5 {
  color: #71efa3;
}

.notify-holder {
  background: $white;
  padding: 1.5rem;

  .btn {
    display: block;
    padding: 10px 44px;
    border: 1px solid #e8760a;
    border-left: 0;
    border-radius: 20px;
    background: $warning;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.not-hired {
  border: 1px solid $black;

  .employee-specs {
    display: flex;
    background: $white;
    padding: 1rem;

    .hire-img img {
      width: 140px;
      height: unset;
    }
    .details {
      padding: 1rem;

      h4 {
        font-size: 1.4em;
        margin-bottom: 2rem;
      }
      div {
        color: $black;
        font-size: 1.1em;
        margin: 10px 0;

        span {
          font-weight: bold;
          opacity: 0.7;
          margin-left: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .flex-form {
    padding: 1rem;
  }
  .w-message {
    padding: 2rem;
  }
  .not-hired {
    .employee-specs {
      .hire-img img {
        min-width: 80px;
        min-height: 80px;
        width: 80px;
      }
    }
  }
}
</style>
