<template>
  <div>
    <h2 class="shipment-header">Παρακολούθηση Μαζικών</h2>

    <section class="bulkOrder-cards">
      <div
        v-for="(bulkOrder, index) in bulkOrders"
        :key="index"
        class="card-container"
      >
        <!-- PICKED UP -->
        <div v-if="bulkOrder.status === 'picked_up'" class="box box-down green">
          <h2>Κατάσταση: <b>Παρελήφθη</b> ({{ bulkOrder.type }})</h2>
          <br />
          <h3>
            Ώρα Παραλαβής: <b>{{ bulkOrder.pickupTime }}</b>
          </h3>
        </div>
        <div
          v-else-if="bulkOrder.status === 'delivered'"
          class="box box-down green"
        >
          <h2>Κατάσταση: <b>Παρελήφθη</b> ({{ bulkOrder.type }})</h2>
          <br />
          <h3>
            Ώρα Παραλαβής: <b>{{ bulkOrder.pickupTime }}</b>
          </h3>
        </div>
        <div
          v-else-if="bulkOrder.status === 'on_the_way_to_deliver'"
          class="box box-down green"
        >
          <h2>Κατάσταση: <b>Παρελήφθη</b> ({{ bulkOrder.type }})</h2>
          <br />
          <h3>
            Ώρα Παραλαβής: <b>{{ bulkOrder.pickupTime }}</b>
          </h3>
        </div>
        <!-- FAILED -->
        <div
          v-else-if="bulkOrder.status === 'failed_pickup'"
          class="box box-down red"
        >
          <h2>Κατάσταση: Αποτυχία παραλαβής ({{ bulkOrder.type }})</h2>
          <h3>Αριθμός Δεμάτων: {{ bulkOrder.numberOfParcels }}</h3>
          <!-- <h3>Αιτιολογία: {{ bulkOrder.pickupFailReason }}</h3> -->
        </div>
        <!-- NOT ROUTED -->
        <div
          v-else-if="bulkOrder.status === 'not_routed'"
          class="box box-down gray"
        >
          <h2>Κατάσταση: Μη δρομολογημένη ({{ bulkOrder.type }})</h2>
          <h3>Αριθμός Δεμάτων: {{ bulkOrder.numberOfParcels }}</h3>
          <h3>ETA : {{ bulkOrder.eta }}</h3>
        </div>
        <!-- ROUTED -->
        <div
          v-else-if="bulkOrder.status === 'matched'"
          class="box box-down blue"
        >
          <h2>Κατάσταση: Δρομολογημένη ({{ bulkOrder.type }})</h2>
          <h3>Οδηγός : {{ bulkOrder.driver }}</h3>
          <h3>Αριθμός Δεμάτων: {{ bulkOrder.numberOfParcels }}</h3>
          <h3>ETA : {{ bulkOrder.eta }}</h3>
        </div>
        <div
          v-else-if="bulkOrder.status === 'on_the_way_to_pickup'"
          class="box box-down blue"
        >
          <h2>Κατάσταση: Δρομολογημένη ({{ bulkOrder.type }})</h2>
          <h3>Οδηγός : {{ bulkOrder.driver }}</h3>
          <h3>Αριθμός Δεμάτων: {{ bulkOrder.numberOfParcels }}</h3>
          <h3>ETA : {{ bulkOrder.eta }}</h3>
        </div>
        <!-- CANCELLED -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "MerchantMainBody",
  props: {
    bulkOrders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style>
:root {
  --red: hsl(348, 100%, 61%);
  --green: hsl(141, 71%, 48%);
  --gray: #d9d9d9;
  --blue: hsl(204, 86%, 53%);
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  height: 50vh;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-container {
  padding: 2px 16px;
  flex: 1;
}

.bulkOrder-cards {
  display: flex;
}

.type-col {
  padding: 16px 16px;
  font-family: "Proxima Nova";
  font-weight: 500;
}

.box {
  border-radius: 5px;
  box-shadow: 0px 30px 40px -20px var(--grayishBlue);
  padding: 30px;
  margin: 20px;
}

.red {
  border-top: 7px solid var(--red);
}

.green {
  border-top: 7px solid var(--green);
}

.blue {
  border-top: 7px solid var(--blue);
}

.gray {
  border-top: 7px solid var(--gray);
}

@media screen and (max-width: 733px) {
  .shipment-eta-container {
    flex-direction: column;
  }
  .delivery-day {
    padding-left: 0px;
    padding-top: 5px;
  }
}

@media screen and (min-width: 734px) {
  section {
    padding: 40px !important;
  }
  .type-col {
    line-height: 34px;
    font-size: 26px;
  }
  .tracking-steps li article {
    margin-left: 40px !important;
  }
  .shipment-state p + p {
    margin-top: 24px !important;
  }
}
</style>
